import React from 'react';
import {
  Link,
  Redirect,
} from "react-router-dom";
import { SendForgotPassword as ApiSendForgotPassword } from '../utils/Api';
import { isAuthenticated } from '../utils/Auth';
import { FormsContext } from '../contexts'

class ForgotPassword extends React.PureComponent {

    state = {
        submitting: false,
        complete: false,
        event: '',
        email: ''
    }

    constructor(props, context) {
        super(props)

        this.submit = this.submit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        context.loadEvents();
    }

    handleChange(event) {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        })
    }

    submit(event) {
        event.preventDefault();

        this.setState({
            ...this.state,
            submitting: true,
        })

        ApiSendForgotPassword(this.state.event, this.state.email).then(() => {
            this.setState({
                ...this.state,
                submitting: false,
                complete: true,
            })
        }).catch(e => {
            alert('There was a problem resetting your password. Please check your email address and try again.');
            this.setState({
                ...this.state,
                submitting: false,
            })
        })
    }

    render() {
        if (isAuthenticated()) {
            return <Redirect
                to={{
                  pathname: "/"
                }} />
        }

        return (
            <div className="sign-in">
                <div className="inner center">
                    <div className="wrapper">
                    {this.state.complete &&
                            <hgroup className="heading-group">
                                <h4 className="heading-group__subtitle">Please check your emails and follow the instructions.</h4>
                            </hgroup>
                    }
                    {!this.state.complete &&
                        <React.Fragment>
                            <hgroup className="heading-group">
                                <h2 className="heading-group__title">Forgot Password</h2>
                                <h4 className="heading-group__subtitle">Enter your email address in to continue</h4>
                            </hgroup>
                            <form onSubmit={this.submit} method="post" className="sign-in__form form">
                                <div className="form__group form__group--dropdown form__group--fixed-width">
                                    <h5 className="form__group__title form__group__title--space">Event</h5>
                                    <div className="form__dropdown">
                                        <select id="event"
                                                name="event"
                                                className="input input--text input--height input--dropdown input--border"
                                                value={this.state.event}
                                                onChange={this.handleChange}>
                                            {this.context.events.length < 1 && !this.context.eventsLoaded &&
                                                <option value="">Loading...</option>
                                            }
                                            {this.context.events.length < 1 && this.context.eventsLoaded &&
                                                <option value="">No events available</option>
                                            }
                                            {this.context.events.length > 0 &&
                                                <React.Fragment>
                                                    <option value=""></option>
                                                    {this.context.events.map(event => <option value={event.id} key={event.id}>{event.name}</option>)}
                                                </React.Fragment>
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="form__group form__group--text">
                                    <h5 className='form__group__title'>Email Address</h5>
                                    <label htmlFor="email">Email Address</label>
                                    <input type="text" id="email" name="email" className="input input--height input--text" placeholder="" value={this.state.email} onChange={this.handleChange} />
                                </div>

                                <div className="form__group form__group--inline form__group--between">
                                    <div className="form__group__half">
                                        <button id="submit" type="submit" className="btn btn--full--blue">{this.state.submitting?'Submitting...':'Continue'}</button>
                                    </div>
                                {!this.state.submitting &&
                                    <div className="form__group__half form__group__half--reset-width">
                                        <Link to="/login" className="forgot-password link link--underline link--right">BACK TO LOGIN</Link>
                                    </div>
                                }
                                </div>

                            </form>
                        </React.Fragment>
                    }

                    </div>
                </div>
            </div>
        )
    }
}

ForgotPassword.contextType = FormsContext;

export default ForgotPassword