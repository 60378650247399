import React from 'react';

export default function Loading() {
    return (
        <div className="terms">
            <div className="inner inner--background small">
                <hgroup className="heading-group heading-group--small">
                    <h2 className="heading-group__title heading-group__title--small">Loading</h2>
                </hgroup>
                <div className="text-block">
                    <p>Please wait while we load all the necessary information!</p>
                </div>
            </div>
        </div>
    )
}