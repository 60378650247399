import React from 'react';
import axios from 'axios'
import {
  Route,
  Redirect,
} from "react-router-dom";

var authenticated = false;

export function isAuthenticated() {
  return authenticated
}

export function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

export function removeAuthToken() {
  localStorage.removeItem('token')
  axios.defaults.headers.common = {}

  authenticated = false
}

export function setAuthToken(authToken) {
  axios.defaults.headers.common = {
    Authorization: 'Bearer ' + authToken
  }
  localStorage.setItem('token', authToken)

  return authToken
}

export function checkExistingAuthToken() {
  const authToken = localStorage.getItem('token')
  if (!authToken) {
    return false
  }

  authenticated = true

  return setAuthToken(authToken)
}

