import React from 'react';
import {
  Link,
  Redirect,
} from "react-router-dom";
import { Login as ApiLogin } from '../utils/Api';
import { checkExistingAuthToken, isAuthenticated, setAuthToken } from '../utils/Auth';
import { FormsContext } from '../contexts'

class SignIn extends React.Component {

    state = {
        error: false,
        submitting: false,
        email: '',
        password: '',
        event: ''
    }

    constructor(props, context) {
        super(props)

        this.handleChange = this.handleChange.bind(this)
        this.doLogin = this.doLogin.bind(this)

        context.loadEvents();
    }

    handleChange(event) {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        })
    }

    doLogin(event) {
        event.preventDefault()

        if (this.state.submitting) {
            return
        }

        this.setState({
            ...this.state,
            error: false,
            submitting: true
        })

        ApiLogin(this.state.event, this.state.email, this.state.password)
        .then(response => {

            setAuthToken(response.data.token)
            checkExistingAuthToken()

            this.props.getInitialData();

            this.props.history.push('/')

        }).catch(() => {
            this.setState({
                ...this.state,
                submitting: false,
                error: true
            })
        })
    }

    render() {

        if (isAuthenticated()) {
            return <Redirect
                to={{
                  pathname: "/"
                }} />
        }

        return (
            <div className="sign-in">
                <div className="inner center">
                    <div className="wrapper">
                        <hgroup className="heading-group">
                            <h2 className="heading-group__title">Welcome</h2>
                            <h4 className="heading-group__subtitle">Sign in to continue</h4>
                        </hgroup>
                        <form onSubmit={this.doLogin} method="post" className="sign-in__form form">
                        {this.state.error &&
                            <div className="form__error">Invalid email/password.</div>
                        }
                            <div className="form__group form__group--dropdown form__group--fixed-width">
                                <h5 className="form__group__title form__group__title--space">Event</h5>
                                <div className="form__dropdown">
                                    <select id="event"
                                            name="event"
                                            className="input input--text input--height input--dropdown input--border"
                                            value={this.state.event}
                                            onChange={this.handleChange}>
                                {this.context.events.length < 1 && !this.context.eventsLoaded &&
                                        <option value="">Loading...</option>
                                }
                                {this.context.events.length < 1 && this.context.eventsLoaded &&
                                        <option value="">No events available</option>
                                }
                                {this.context.events.length > 0 &&
                                    <React.Fragment>
                                        <option value=""></option>
                                    {this.context.events.map(event => <option value={event.id} key={event.id}>{event.name}</option>)}
                                    </React.Fragment>
                                }
                                    </select>
                                </div>
                            </div>
                            <div className="form__group form__group--text">
                                <h5 className='form__group__title'>Email Address</h5>
                                <label htmlFor="email">Email Address</label>
                                <input type="text" id="email" name="email" className="input input--height input--text" placeholder="someone@example.com" onChange={this.handleChange} value={this.state.email} />
                            </div>
                            <div className="form__group form__group--text">
                                <h5 className='form__group__title'>Password</h5>
                                <label htmlFor="password">Password</label>
                                <input type="password" id="password" name="password" className="input input--height input--text" placeholder="••••••" onChange={this.handleChange} value={this.state.password} />
                            </div>
                            <div className="form__group form__group--inline form__group--between">
                                <div className="form__group__half">
                                    <button id="submit" type="submit" className="btn btn--full--blue">{this.state.submitting?'Submitting...':'Login'}</button>
                                </div>
                            {!this.state.submitting &&
                                <div className="form__group__half form__group__half--reset-width">
                                    <Link to="/forgot-password" className="forgot-password link link--underline link--right">FORGOT PASSWORD</Link>
                                </div>
                            }
                            </div>

                        </form>

                    </div>
                </div>
            </div>
        )
    }
}

SignIn.contextType = FormsContext;

export default SignIn;