import React from 'react';
import Papa from 'papaparse';
import {
  Redirect,
} from "react-router-dom";
import { AddTeamMember as ApiAddTeamMember, AddTeamMembers as ApiAddTeamMembers, ToggleTeamMember as ApiToggleTeamMember } from '../utils/Api'
import { FormErrors } from '../constants'
import Sidebar from '../components/Sidebar';
import { FormsContext } from '../contexts'

class AddTeamMember extends React.PureComponent {

    state = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        errors: {},
        submitting: false,
        importing: false
    }

    constructor(props) {
        super(props)

        this.fileUpload = React.createRef();

        this.addCsv = this.addCsv.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validate = this.validate.bind(this);
        this.toggleTeamMember = this.toggleTeamMember.bind(this)

        this.mounted = false;
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    validate(event) {
        event.preventDefault();

        let errors = {}
        if (this.state.firstName.trim() === '') {
            errors['firstName'] = FormErrors.required
        }

        if (this.state.lastName.trim() === '') {
            errors['lastName'] = FormErrors.required
        }

        const validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.state.email.trim());
        if (!validEmail) {
            errors['email'] = FormErrors.required
        }

        if (this.state.phone.trim() === '') {
            errors['phone'] = FormErrors.required
        }

        if (Object.keys(errors).length > 0) {
            this.setState({
                ...this.state,
                errors
            })
            return
        }

        this.setState({
            ...this.state,
            errors: {},
            submitting: true
        })

        ApiAddTeamMember(this.state.firstName, this.state.lastName, this.state.email, this.state.phone)
            .then(response => {
                this.context.reloadTeam()
                this.props.history.push('/team')
            })
            .catch(data => {
                this.setState({
                    ...this.state,
                    errors: data.data.errors,
                    submitting: false
                })
            })
    }

    toggleTeamMember(event) {
        if (!this.context.canAddTeamMember()) {
            alert('You must remove a team member before you can add yourself.')
            return
        }

        if (!confirm('Are you sure you want to mark yourself as a team member coming to site?\n\nThis cannot be undone.')) { //eslint-disable-line no-restricted-globals
            event.preventDefault();
            return
        }

        let isTeamMember = event.target.value === "1";

        this.context.toggleIsTeamMember(isTeamMember)

        ApiToggleTeamMember(event.target.value === "1").then(data => {
            this.context.toggleIsTeamMember(data.data.isTeamMember)
        }).catch(error => {
            this.context.toggleIsTeamMember(!isTeamMember)
        })
    }

    componentDidMount() {
        this.mounted = true
    }

    componentWillUnmount() {
        this.mounted = false
    }

    addCsv(event) {
        if (this.state.importing) {
            return
        }

        const file = event.target.files[0]
        if (!file) {
            return
        }

        const extension = (/[.]/.exec(file.name)) ? /[^.]+$/.exec(file.name) : undefined;

        if (!extension || extension[0].toLowerCase() !== 'csv') {
            alert('Invalid file type.')
            return
        }

        this.fileUpload.current.value = null;

        this.setState({
            ...this.state,
            importing: true
        })

        Papa.parse(file, {
            complete: (results, file) => {
                if (!this.mounted) {
                    return
                }

                if (results.errors.length > 0) {
                    let errors = results.errors.map(error => {
                        return error.message
                    })
                    alert(errors.join('\n'))
                    return
                }

                let members = []
                let errors = []
                results.data.forEach((line, i) => {
                    const lineNumber = i + 1

                    if (lineNumber > 0 && line[0] === "" && line.length === 1) {
                        return true;
                    }

                    try {
                        line[0] = line[0].trim()
                        line[1] = line[1].trim()
                        line[2] = line[2].trim()
                        line[3] = line[3].trim()
                    } catch (e) {
                        errors.push(`Line ${lineNumber}: All columns are required`)
                        return true
                    }

                    if (line[0] === '' || line[1] === '' || line[2] === '' || line[3] === '') {
                        errors.push(`Line ${lineNumber}: All columns are required`)
                        return true
                    }

                    const validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(line[2]);
                    if (!validEmail) {
                        if (i === 0) {
                            return true
                        }

                        errors.push(`Line ${lineNumber}: Invalid Email`)
                        return true
                    }

                    members.push({
                        lineNumber: lineNumber,
                        firstName: line[0],
                        lastName: line[1],
                        email: line[2],
                        phone: line[3],
                    })
                })

                if (errors.length > 0) {
                    alert("Errors:\n" + errors.join("\n"))
                    this.setState({
                        ...this.state,
                        importing: false
                    })
                    return
                }

                if (!this.context.canAddTeamMember(members.length)) {
                    const remaining = this.context.remainingTeamMemberLimit();

                    alert(`You cannot add ${members.length} team members. You only have ${remaining} ${(() => (remaining > 1?'spaces':'space'))()} remaining.`)
                    this.setState({
                        ...this.state,
                        importing: false
                    })
                    return
                }

                ApiAddTeamMembers(members)
                .then(response => {
                    if (response.data.errors.length > 0) {
                        alert("Errors:\n" + response.data.errors.join("\n"))
                    }

                    this.context.reloadTeam()
                    this.props.history.push('/team')
                }).catch(data => {
                    alert("Errors:\n" + data.data.errors.join("\n"))
                    this.setState({
                        ...this.state,
                        importing: false
                    })
                })

            }
        })

    }

    render() {
        if (!this.context.teamSettings.hasTeam) {
            return <Redirect
                to={{
                  pathname: "/"
                }} />
        }

        const canAddTeamMember = this.context.canAddTeamMember()
        const spacesRemaining = this.context.remainingTeamMemberLimit();

        return (
            <div className="add-team-member">
                <div className="inner desktop-flex not-mobile">
                    <Sidebar pathname={this.props.location.pathname} />
                    <div className="inner inner--background half">
                        <div className="wrapper wrapper--gap wrapper--left wrapper--auto wrapper--gap--small">

                        {!this.context.teamSettings.isTeamMember &&
                            <form method="post" className="first__form form">
                                <div className="form__group form__group--radio form__group--border">
                                    <h5 className='form__group__title form__group__title--space'>Are you part of the team coming to site?</h5>
                                    <div className="form__group__holder">
                                        <input id="option-yes" type="radio" name="team-member" value="1" onChange={this.toggleTeamMember} checked={this.context.teamSettings.isTeamMember} />
                                        <label htmlFor="option-yes">Yes</label>
                                    </div>
                                    <div className="form__group__holder">
                                        <input id="option-no" type="radio" name="team-member" value="0" onChange={this.toggleTeamMember} checked={!this.context.teamSettings.isTeamMember} />
                                        <label htmlFor="option-no">No</label>
                                    </div>
                                </div>
                            </form>
                        }

                        {canAddTeamMember &&
                            <React.Fragment>
                                <hgroup className="heading-group">
                                    <h2 className="heading-group__title heading-group__title--custom" >Add team member</h2>
                                    <h4 className="heading-group__subtitle heading-group__subtitle--medium">Enter team details or upload CSV</h4>
                                {spacesRemaining !== null &&
                                    <h4 className="heading-group__subtitle heading-group__subtitle--medium">You have <strong>{spacesRemaining} space{spacesRemaining > 1?'s':''}</strong> remaining</h4>
                                }
                                </hgroup>

                                <form onSubmit={this.validate} method="post" className="first__form form">
                                {!this.state.importing &&
                                    <React.Fragment>
                                        <div className="form__group form__group--between form__group--text form__group--mobile-column form__group--fixed-width">
                                            <div className="form__group__half">
                                                <h5 className='form__group__title form__group__title--space'>FIRST NAME</h5>
                                                <input type="text" name="firstName" className="input input--text input--height input--border" placeholder="First Name" value={this.state.firstName} onChange={this.handleChange} />
                                            {this.state.errors["firstName"] &&
                                                <div className="form__error">{this.state.errors["firstName"]}</div>
                                            }
                                            </div>
                                            <div className="form__group__half">
                                                <h5 className='form__group__title form__group__title--space'>LAST NAME</h5>
                                                <input type="text" name="lastName" className="input input--text input--height input--border" placeholder="Last Name" value={this.state.lastName} onChange={this.handleChange} />
                                            {this.state.errors["lastName"] &&
                                                <div className="form__error">{this.state.errors["lastName"]}</div>
                                            }
                                            </div>
                                        </div>
                                        <div className="form__group form__group--text form__group--fixed-width">
                                            <h5 className='form__group__title form__group__title--space'>EMAIL ADDRESS</h5>
                                            <input type="text" name="email" className="input input--text input--height input--border" placeholder="Email Address" value={this.state.email} onChange={this.handleChange} />
                                            {this.state.errors["email"] &&
                                                <div className="form__error">{this.state.errors["email"]}</div>
                                            }
                                        </div>
                                        <div className="form__group form__group--text form__group--gap form__group--fixed-width">
                                            <h5 className='form__group__title form__group__title--space'>PHONE NUMBER</h5>
                                            <input type="text" name="phone" className="input input--text input--height input--border" placeholder="Phone Number" value={this.state.phone} onChange={this.handleChange} />
                                            {this.state.errors["phone"] &&
                                                <div className="form__error">{this.state.errors["phone"]}</div>
                                            }
                                        </div>

                                        <div className={"form__group form__group--btn" + (!this.state.submitting?" form__group--border":"")}>
                                            <button type="submit" className="btn btn--full--blue btn--full--wide">{this.state.submitting?"Adding...":"Add team member"}</button>
                                        </div>
                                    </React.Fragment>
                                }

                                {!this.state.submitting &&
                                    <div className="form__group form__group--upload">
                                        <h5 className='form__group__title form__group__title--space'>ADD MULTIPLE TEAM MEMBERS</h5>
                                        <h5 className='form__group__title form__group__title--reset-top'>UPLOAD CSV</h5>
                                        <div className="file-upload">
                                            <div className="btn btn--full--blue">
                                                {!this.state.importing &&
                                                    <div>
                                                        Upload
                                                        <input type="file" ref={this.fileUpload} onChange={this.addCsv} />
                                                    </div>
                                                }

                                                {this.state.importing &&
                                                    <span>Importing...</span>
                                                }
                                            </div>
                                            <p>Please format your CSV with the following columns:<br />First Name, Last Name, Email Address, Phone<br /><br />DO NOT include a header row.</p>
                                        </div>
                                    </div>
                                }

                                </form>
                            </React.Fragment>
                        }

                        {!canAddTeamMember &&
                            <hgroup className="heading-group">
                                <h2 className="heading-group__title heading-group__title--custom">No spaces remaining</h2>
                                <h4 className="heading-group__subtitle heading-group__subtitle--medium">You must remove a team member before you can add another.</h4>
                            </hgroup>
                        }

                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

AddTeamMember.contextType = FormsContext;

export default AddTeamMember