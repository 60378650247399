import React from 'react';

export default function NotFound() {
    return (
        <div className="terms">
            <div className="inner inner--background small">
                <hgroup className="heading-group heading-group--small">
                    <h2 className="heading-group__title heading-group__title--small" >Page not Found</h2>
                </hgroup>
                <div className="text-block">
                    <p>
                        The page you're requested cannot be found
                    </p>
                </div>
            </div>
        </div>
    )
}