import React from 'react';
import {
  Redirect
} from "react-router-dom";
import { SubmitForm as ApiSubmitForm, GetUploadPolicy as ApiGetUploadPolicy } from '../utils/Api';
import Form from '../components/Form';
import { FormsContext } from '../contexts'

class FormPage extends React.PureComponent {

    constructor(props) {
        super(props)

        this.submit = this.submit.bind(this)
        this.getUploadPolicy = this.getUploadPolicy.bind(this)
    }

    submit() {
        const id = this.props.match.params.id
        return ApiSubmitForm(id, this.context.answers)
    }

    getUploadPolicy(questionId, fileName, contentType) {
        const id = this.props.match.params.id
        return ApiGetUploadPolicy(id, questionId, fileName, contentType)
    }

    render() {
        const id = this.props.match.params.id
        let form = null
        let formI = null
        this.context.forms.forEach((f, i) => {
            if (parseInt(f.id) === parseInt(id)) {
                form = f
                formI = i
                return false
            }
        })

        if (form === null || !form.editable) {
            return <Redirect
                to={{
                  pathname: "/"
                }} />
        }

        if (!form.questions) {
            form.questions = []
        }

        if (!form.answers) {
            form.answers = {}
        }

        let next = false
        for (let i = formI + 1, n = this.context.forms.length; i < n; i++) {
            if (this.context.forms[i].editable) {
                next = this.context.forms[i]
                break
            }
        }

        return (
            <div className="form-page">
                <Form form={form} history={this.props.history} onSubmit={this.submit} next={next} getUploadPolicy={this.getUploadPolicy} />
            </div>
        )
    }
}

FormPage.contextType = FormsContext;

export default FormPage