import React from 'react'
import {
  Link
} from "react-router-dom";
import { FormErrors } from '../constants'
import Sidebar from '../components/Sidebar'
import { FormsContext } from '../contexts'

import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'
import { DragDrop } from '@uppy/react'

import '@uppy/drag-drop/dist/style.min.css'

class Form extends React.PureComponent {

    state = {
        errors: {},
        submitting: false,
        complete: false
    }

    constructor(props, context) {
        super(props)

        this.drawQuestion = this.drawQuestion.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.validate = this.validate.bind(this)
        this.isQuestionVisible = this.isQuestionVisible.bind(this)
        this.changed = false

        this.unblock = props.history.block((location, action) => {
            if (this.changed) {
                return 'Are you sure you want to leave this page?';
            }
        })

        if (process.env.NODE_ENV === 'production') {
            window.onbeforeunload = () => {
                if (this.changed) {
                    return false
                }
            }
        }

        this.unlisten = props.history.listen((location, action) => {
            this.changed = false
        })

        context.setAnswers(props.form.answers);
    }

    handleChange(event, question, answer = "") {
        this.changed = true;

        switch (question.type) {
            case 'text-single':
            case 'text-multi':
            case 'select-single':
            case 'radio':
                return this.context.updateAnswer(event.target.name, event.target.value)

            case 'checkbox':
                let answers = this.context.answers[event.target.name]

                if (typeof answers !== "object") {
                    answers = []
                }

                if (answers.indexOf(event.target.value) > -1) {
                    let newAnswers = []
                    answers.forEach(a => {
                        if (event.target.value === a) {
                            return true
                        }

                        newAnswers.push(a)
                    })
                    answers = newAnswers
                } else if (event.target.checked) {
                    answers.push(event.target.value)
                }

                return this.context.updateAnswer(event.target.name, answers)

            case 'upload':
                return this.context.updateAnswer("q" + question.id, answer)

            default:
                return
        }
    }

    drawQuestion(question) {
        if (!this.isQuestionVisible(question)) {
            return null
        }

        switch (question.type) {
            case 'text-single':
                return (
                    <div className="form__group form__group form__group--fixed-width" key={question.id}>
                        <h5 className="form__group__title form__group__title--space form__group__title">{question.name}</h5>
                    {question.note &&
                        <p dangerouslySetInnerHTML={{__html: question.note}} />
                    }
                        <input type="text"
                               id={"q" + question.id}
                               name={"q" + question.id}
                               className="input input--text input--height input--border"
                               placeholder="Answer"
                               value={this.context.answers["q" + question.id] || ''}
                               onChange={(event) => this.handleChange(event, question)} />
                    {this.state.errors && this.state.errors["q" + question.id] &&
                        <div className="form__error">{this.state.errors["q" + question.id]}</div>
                    }
                    </div>
                )

            case 'text-multi':
                return (
                    <div className="form__group form__group form__group--fixed-width" key={question.id}>
                        <h5 className="form__group__title form__group__title--space form__group__title">{question.name}</h5>
                    {question.note &&
                        <p dangerouslySetInnerHTML={{__html: question.note}} />
                    }
                        <textarea id={"q" + question.id}
                                  name={"q" + question.id}
                                  className="input input--text input--multi input--border"
                                  placeholder="Answer"
                                  value={this.context.answers["q" + question.id] || ''}
                                  onChange={(event) => this.handleChange(event, question)}
                                  rows="10" />
                    {this.state.errors && this.state.errors["q" + question.id] &&
                        <div className="form__error">{this.state.errors["q" + question.id]}</div>
                    }
                    </div>
                )

            case 'select-single':
                return (
                    <div className="form__group form__group--dropdown form__group--fixed-width" key={question.id}>
                        <h5 className="form__group__title form__group__title--space">{question.name}</h5>
                    {question.note &&
                        <p dangerouslySetInnerHTML={{__html: question.note}} />
                    }
                        <div className="form__dropdown">
                            <select id={"q" + question.id}
                                    name={"q" + question.id}
                                    className="input input--text input--height input--dropdown input--border"
                                    value={this.context.answers["q" + question.id] || ''}
                                    onChange={(event) => this.handleChange(event, question)}>
                                <option value="" key=""></option>
                                {question.options.map(option => <option value={option.value} key={option.value}>{option.label}</option>)}
                            </select>
                        </div>
                    {this.state.errors && this.state.errors["q" + question.id] &&
                        <div className="form__error">{this.state.errors["q" + question.id]}</div>
                    }
                    </div>
                )

            case 'radio':
                return (
                    <div className="form__group form__group--radio" key={question.id}>
                        <h5 className="form__group__title form__group__title--space">{question.name}</h5>
                    {question.note &&
                        <p dangerouslySetInnerHTML={{__html: question.note}} />
                    }
                        <div>
                        {question.options.map((option, optioni) => (
                            <div className="form__group__holder" key={question.id + "_" + optioni}>
                                <input id={"option-" + question.id + "_" + optioni}
                                       type="checkbox"
                                       value={option.value}
                                       name={"q" + question.id}
                                       checked={this.context.answers["q" + question.id] === `${option.value}`}
                                       onChange={(event) => this.handleChange(event, question)} />
                                <label htmlFor={"option-" + question.id + "_" + optioni}>{option.label}</label>
                            </div>
                        ))}
                        </div>
                    {this.state.errors && this.state.errors["q" + question.id] &&
                        <div className="form__error">{this.state.errors["q" + question.id]}</div>
                    }
                    </div>
                )

            case 'checkbox':
                return (
                    <div className="form__group form__group--checkbox" key={question.id}>
                        <h5 className="form__group__title form__group__title--space">{question.name}</h5>
                    {question.note &&
                        <p dangerouslySetInnerHTML={{__html: question.note}} />
                    }
                        <div>
                    {question.options.map((option, optioni) => {
                        const checked = this.context.answers["q" + question.id] && this.context.answers["q" + question.id].indexOf(`${option.value}`) > -1
                        return (
                            <div className="form__group__holder" key={question.id + "_" + optioni}>
                                <input id={"option-" + question.id + "_" + optioni}
                                       type="checkbox"
                                       value={option.value}
                                       name={"q" + question.id}
                                       checked={checked || false}
                                       onChange={(event) => this.handleChange(event, question)}
                                       className="input input--checkbox" />
                                <label htmlFor={"option-" + question.id + "_" + optioni} className="dark">{option.label}</label>
                            </div>
                        )
                    })}
                        </div>
                    {this.state.errors && this.state.errors["q" + question.id] &&
                        <div className="form__error">{this.state.errors["q" + question.id]}</div>
                    }
                    </div>
                )

            case 'upload':
                return (
                    <div className="form__group form__group form__group--fixed-width" key={question.id}>
                        <h5 className="form__group__title form__group__title--space form__group__title">{question.name}</h5>
                    {question.note &&
                        <p dangerouslySetInnerHTML={{__html: question.note}} />
                    }

                    {question.acceptedFileTypes &&
                        <p>Accepted file types: {question.acceptedFileTypes.join(', ')}</p>
                    }

                    {this.context.answers["q" + question.id] &&
                        <p className="form__file-upload-name">Current file: {this.context.answers["q" + question.id]}</p>
                    }

                    <Upload form={this.props.form}
                            question={question}
                            setError={error => this.setState({
                                    ...this.state,
                                    errors: {
                                        ...this.state.errors,
                                        ["q" + question.id]: error
                                    }
                            })}
                            uploadSuccess={file => this.handleChange(false, question, file)}
                            getUploadPolicy={this.props.getUploadPolicy} />

                    {this.state.errors && this.state.errors["q" + question.id] &&
                        <div className="form__error">{this.state.errors["q" + question.id]}</div>
                    }
                    </div>
                )

            case 'heading':
                return (
                    <hgroup className="heading-group heading-group--margin-top" key={question.id}>
                        <h2 className="heading-group__title heading-group__title--medium">{question.name}</h2>
                    {question.note &&
                        <h4 className="heading-group__subtitle heading-group__subtitle--medium" dangerouslySetInnerHTML={{__html: question.note}} />
                    }
                    </hgroup>
                )

            case 'body-text':
                return (
                    <hgroup className="heading-group heading-group--margin-top-small" key={question.id}>
                        <h4 className="heading-group__subtitle heading-group__subtitle--medium" dangerouslySetInnerHTML={{__html: question.name}} />
                    </hgroup>
                )

            default:
                return null
        }
    }

    validate(event) {
        event.preventDefault()

        if (this.state.submitting) {
            return false
        }

        let errors = {}
        this.props.form.questions.forEach(question => {
            if (!this.isQuestionVisible(question)) {
                return true
            }

            if (question.type === "heading" || question.type === "body-text") {
                return true
            }

            const answer = this.context.answers["q" + question.id]
            if (question.type === "checkbox") {
                if (!answer || answer.length === 0) {
                    errors["q" + question.id] = FormErrors.required
                }
                return true
            }

            if (!answer) {
                errors["q" + question.id] = FormErrors.required
            }
        })

        if (Object.keys(errors).length > 0) {
            this.setState({
                ...this.state,
                errors
            })
            return
        }

        this.setState({
            ...this.state,
            submitting: true,
            errors: {}
        })

        this.props.onSubmit()
            .then(data => {
                if (!this.props.single) {
                    this.context.updateForm(this.props.form.id, data.data)
                }

                this.changed = false

                this.setState({
                    ...this.state,
                    submitting: false,
                    complete: true,
                    errors: {}
                })
                // this.props.history.push('/')
            })
            .catch(data => {
                this.setState({
                    ...this.state,
                    submitting: false,
                    errors: data.errors
                })
            })
    }

    isQuestionVisible(question) {
        if (question.dependsOn) {
            if (typeof this.context.answers["q" + question.dependsOn.id] === "object") {
                return this.context.answers["q" + question.dependsOn.id].indexOf(question.dependsOn.value) > -1
            }

            if (this.context.answers["q" + question.dependsOn.id] !== question.dependsOn.value) {
                return false
            }
        }

        return true
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.form.id !== this.props.form.id) {
            this.context.setAnswers(nextProps.form.answers);

            setTimeout(() => {
                this.setState({
                    ...this.state,
                    submitting: false,
                    complete: false,
                    errors: {}
                })
            }, 1)

            return
        }
    }

    componentWillUnmount() {
        this.unblock();
        this.unlisten();
        if (process.env.NODE_ENV === 'production') {
            window.onbeforeunload = null;
        }
        this.context.clearAnswers();
    }

    render() {
        let form = this.props.form

        return (
            <div className="inner desktop-flex not-mobile">
            {!this.props.single &&
                <Sidebar form={form.id} />
            }
                <div className={"inner inner--background" + (!this.props.single?" half":"")}>

                {this.state.complete &&
                    <div className="overlay overlay--complete">
                        <div className="container">
                            <h1 className="overlay__title">Success!</h1>
                        {this.props.single &&
                            <p className="overlay__next-form">Thank you for submitting this form.</p>
                        }
                        {this.props.next &&
                            <React.Fragment>
                                <Link to={"/forms/" + this.props.next.id} className="overlay__link btn btn--middle btn--full--blue">Continue</Link>
                                <p className="overlay__next-form">Next Form: <span>{this.props.next.name}</span></p>
                            </React.Fragment>
                        }
                        </div>
                    </div>
                }

                    <div className={"wrapper wrapper--gap wrapper--left wrapper--auto wrapper--gap--small" + (this.state.complete?" wrapper--overlay-complete":"")}>

                    {form.statusNote && form.editable &&
                        <hgroup className="heading-group">
                            <h2 className="heading-group__title heading-group__title--warning heading-group__title--medium">Action Needed</h2>
                            <h4 className="heading-group__subtitle heading-group__subtitle--warning heading-group__subtitle--medium">{form.statusNote}</h4>
                        </hgroup>
                    }

                        <hgroup className="heading-group">
                            <h2 className="heading-group__title heading-group__title--medium">{form.name}</h2>
                            <h4 className="heading-group__subtitle heading-group__subtitle--medium">{form.description}</h4>
                        </hgroup>

                    {form.supportingDocuments.length > 0 &&
                        <ul className="bulleted-list">
                        {form.supportingDocuments.map(doc => <li key={doc.url}><a href={doc.url} className="link link--large link--highlight" target="_blank" rel="noopener noreferrer">{doc.name}</a></li>)}
                        </ul>
                    }

                        <form onSubmit={this.validate} className="first__form form">
                        {form.questions.map(question => this.drawQuestion(question))}
                            <br />
                            <br />
                            <button type="submit" className="btn btn--right btn--full--blue" disabled={this.state.submitting}>{this.state.submitting?"Submitting...":"Submit"}</button>
                        </form>

                    </div>
                </div>

            </div>
        )
    }
}

Form.contextType = FormsContext;

export default Form

class Upload extends React.PureComponent {

    state = {
        uploading: false
    }

    constructor(props) {
        super(props);

        this.uppy = Uppy({
            id: "q" + props.question.id,
            autoProceed: true,
            onBeforeFileAdded: (currentFile, files) => {
                if (!props.question.acceptedFileTypes) {
                    return true
                }

                if (props.question.acceptedFileTypes.indexOf(currentFile.name.split('.').pop().toLowerCase()) > -1) {
                    return true
                }

                let fileTypes = props.question.acceptedFileTypes.join(', ')
                props.setError(`Invalid file type. Must be ${fileTypes}.`);
                return false
            }
        })

        this.uppy.use(AwsS3, {
            getUploadParameters: file => {
                return props.getUploadPolicy(props.question.id, file.name, file.type)
            }
        });

        this.uppy.on('upload', () => {
            this.setState({
                uploading: true
            })
        })

        this.uppy.on('upload-error', (file, error) => {
            this.setState({
                uploading: false
            })

            props.setError(`Upload Failed. ${error}`);
        })

        this.uppy.on('upload-success', (file, response) => {
            this.setState({
                uploading: false
            })
            props.uploadSuccess(file.meta.name);
        })
    }

    render() {
        if (this.state.uploading) {
            return <span>Uploading...</span>
        }

        return (
            <DragDrop uppy={this.uppy} locale={{ strings: { dropHereOr: `Drop here or %{browse}` }}}  />
        )
    }
}