import React from 'react';
import {
  Link,
  withRouter
} from "react-router-dom";
import { isAuthenticated } from '../utils/Auth';

function Header(props) {
    const showHeaderLink = !(props.location.pathname.indexOf('/single-form/') > -1 || props.location.pathname.indexOf('/invite/') > -1)
    return (
        <header className="header">
            <div className="inner desktop-flex">
                <nav className="nav-top">
                    <div className="nav-top__left">
                    {showHeaderLink &&
                        <Link to="/" className="nav-top__left__item">Accreditation Portal</Link>
                    }
                    {!showHeaderLink &&
                        <span className="nav-top__left__item">Accreditation Portal</span>
                    }
                    </div>
                {isAuthenticated() &&
                    <>
                        <div className="nav-top__right desktop">
                            <button onClick={props.doLogout} className="nav-top__right__item btn btn--full btn--shadow">Sign Out</button>
                        </div>
                        <button onClick={props.toggleMobileMenu} className="menu-toggle mobile">
                            <span></span>
                        </button>
                    </>
                }

                </nav>

            </div>
            <div className="header__image"></div>

        {isAuthenticated() &&
            <>
                <div className="nav-mobile">
                    <div className="nav-mobile__top">
                        <div className="container">
                            <Link to="/" className="nav-mobile__top__title" onClick={props.toggleMobileMenu}>Your forms</Link>
                        </div>
                        {props.hasTeam &&
                            <div className="container">
                                <h2 className="nav-mobile__top__title">Your team</h2>
                                <Link to="/team/add" className="nav-mobile__top__link link link--white" onClick={props.toggleMobileMenu}>Add team member</Link>
                                <Link to="/team" className="nav-mobile__top__link link link--white" onClick={props.toggleMobileMenu}>View team member</Link>
                            </div>
                        }
                    </div>

                    <div className="nav-mobile__bottom">
                        <h3>{props.name}</h3>
                        <h6>{props.company}</h6>
                        <div className="nav-mobile__bottom__link">
                            <button onClick={() => {
                                props.toggleMobileMenu();
                                props.doLogout();
                            }} className="link link--underline link--white">Sign Out</button>
                        </div>

                    </div>
                </div>
            </>
        }
        </header>
    )
}

export default withRouter(Header)