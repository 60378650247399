import React from 'react';

export const FormsContext = React.createContext({
    loading: true,
    setLoading: () => {},
    events: [],
    loadEvents: () => {},
    forms: [],
    updateForm: () => {},
    answers: {},
    setAnswers: () => {},
    updateAnswer: () => {},
    clearAnswers: () => {},
    team: [],
    reloadTeam: () => {},
    removeTeamMember: () => {},
    toggleIsTeamMember: () => {},
    account: {},
    setAccount: () => {},
    canAddTeamMember: () => {},
    countTeamMembers: () => {},
    remainingTeamMemberLimit: () => {},
});
