import React from 'react';
import bottom from '../img/asset.bottom.png';

export default function Footer() {
    return (
        <footer className="footer">
            <div className="border--blue"></div>
            <div className="footer__content">
                <div className="inner wide">
                    <div className="footer__copyright">
                        <p>&copy; {(new Date().getFullYear())}. Big Church Day Out. All Rights Reserved</p>
                    </div>
                    <nav className="footer__nav">
                        <a href="https://www.bigchurchdayout.com/privacy-policy/" className="footer-link" rel="noreferrer noopener" target="_blank">Privacy Policy</a>
                        &nbsp;
                        <a href="https://www.bigchurchdayout.com/contact-us/" className="footer-link" rel="noreferrer noopener" target="_blank">Contact</a>
                    </nav>
                </div>
            </div>

            <div className="footer__image tablet">
                <img src={bottom} alt="Accreditation Portal" />
            </div>
        </footer>
    )
}