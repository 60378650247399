import React from 'react';
import {
  Link
} from "react-router-dom";
import { FormsContext } from '../contexts'
import { FormStatuses } from '../constants'

class Sidebar extends React.Component {
    render() {
        return (
            <nav className="nav-sidebar desktop">
                <div className='container'>
                    <h2>Your forms</h2>
                    {this.context.forms.map(form => {
                        const status = FormStatuses[form.status]
                        return (
                            <div className='nav-sidebar__item' key={form.id}>
                            {form.editable &&
                                <Link to={"/forms/" + form.id} className={"nav-sidebar__link link link--" + status.class + (this.props.form && this.props.form === form.id?" link--active":"")}>
                                    - {form.name}{form.compulsory && <span>*</span>}
                                </Link>
                            }
                            {!form.editable &&
                                <p className={"nav-sidebar__link link link--" + status.class + (this.props.form && this.props.form === form.id?" link--active":"")}>
                                    - {form.name}
                                </p>
                            }
                                <p className={"btn btn--status btn--radius btn--full--" + status.class}>{status.name}</p>
                            </div>
                        )
                    })}
                </div>

            {this.context.teamSettings.hasTeam &&
                <div className='container'>
                    <h2>Your teams</h2>
                    <div className="nav-sidebar__item">
                        <Link to="/team/add" className={"nav-sidebar__link link" + (this.props.pathname === "/team/add"?" link--active":"")}>
                            - Add team member
                        </Link>
                    </div>
                    <div className="nav-sidebar__item">
                        <Link to="/team" className={"nav-sidebar__link link" + (this.props.pathname === "/team"?" link--active":"")}>
                            - View team members
                        </Link>
                    </div>
                </div>
            }
            </nav>
        )
    }
}

Sidebar.contextType = FormsContext;

export default Sidebar