import React from 'react';
import {
  Redirect,
} from "react-router-dom";
import { FormsContext } from '../contexts'
import { RemoveTeamMember as ApiRemoveTeamMember, ResendTeamMemberForm as ApiResendTeamMemberForm, GetEticket as ApiGetEticket } from '../utils/Api'
import Sidebar from '../components/Sidebar';
import { FormStatuses } from '../constants'

class ViewTeamMembers extends React.PureComponent {

    constructor(props) {
        super(props)

        this.removeTeamMember = this.removeTeamMember.bind(this)
        this.resendForm = this.resendForm.bind(this)
    }

    removeTeamMember(event, member) {
        event.preventDefault()

        if (!confirm(`Are you sure you want to remove ${member.name}?`)) { //eslint-disable-line no-restricted-globals
            return
        }

        ApiRemoveTeamMember(member.id).then(response => {
            this.context.removeTeamMember(member.id)
        })
    }

    getEticket(event, member) {
        event.preventDefault()

        ApiGetEticket(member.id).then(response => {
            window.open(response.data.eticket)
        }).catch(() => {
            alert('Could not get e-ticket. Please try again later.')
        })
    }

    resendForm(event, member, form) {
        event.preventDefault()

        if (!confirm(`Are you sure you want to resend "${form.name}" to ${member.name}?`)) { //eslint-disable-line no-restricted-globals
            return
        }

        ApiResendTeamMemberForm(form.id, member.id).finally(() => {
            alert(`"${form.name}" was resent to ${member.name}`);
        })
    }

    render() {
        if (!this.context.teamSettings.hasTeam) {
            return <Redirect
                to={{
                  pathname: "/"
                }} />
        }

        return (
            <div className="view-team">
                <div className="inner desktop-flex not-mobile">
                    <Sidebar pathname={this.props.location.pathname} />
                    <div className="inner inner--background half">
                        <div className="wrapper wrapper--gap wrapper--left wrapper--auto wrapper--gap--smallest">
                            <hgroup className="heading-group">
                                <h2 className="heading-group__title" >View team members</h2>
                                <h4 className="heading-group__subtitle heading-group__subtitle--medium">Check if your team have filled in their forms</h4>
                            </hgroup>

                            <div className="team">
                        {this.context.teamSettings.isTeamMember &&
                                <div className="team-member-details">
                                    <div className="personal-details">
                                        <hgroup>
                                            <h4 className='personal-details__name'>{this.context.account.name}<span className="tablet"> | </span></h4>
                                            <h5 className='personal-details__email'>{this.context.account.email}</h5>
                                        </hgroup>
                                        <span className="link link--right link--light">Yourself</span>
                                    </div>
                                {this.context.teamSettings.hasOrder &&
                                    <div className="personal-details">
                                        <hgroup>&nbsp;</hgroup>
                                        <button onClick={event => this.getEticket(event, this.context.account)} className="team-member-remove link link--underline link--right link--light">download eticket</button>
                                    </div>
                                }
                                </div>
                        }
                        {this.context.team.map(member => {
                            let hasOutstandingForm = false;
                            return (
                                <div className="team-member-details" key={member.id}>
                                    <div className="personal-details">
                                        <hgroup>
                                            <h4 className='personal-details__name'>{member.name}<span className="tablet"> | </span></h4>
                                            <h5 className='personal-details__email'>{member.email}</h5>
                                        </hgroup>
                                        <button onClick={event => this.removeTeamMember(event, member)} className="team-member-remove link link--underline link--right link--light">remove</button>
                                    </div>
                                {member.hasOrder &&
                                    <div className="personal-details">
                                        <hgroup>&nbsp;</hgroup>
                                        <button onClick={event => this.getEticket(event, member)} className="team-member-remove link link--underline link--right link--light">download eticket</button>
                                    </div>
                                }
                                    <div className="form-details">
                                        <div className="container">
                                            {member.forms.map(form => {
                                                hasOutstandingForm = hasOutstandingForm || form.editable;
                                                return (
                                                    <div className="form-details__form" key={member.id+"_"+form.id}>
                                                        <p className={"btn btn--status btn--square btn--full--no-background btn--full--" + (form.editable?"resend":FormStatuses[form.status].class)}>{form.name}</p>
                                                    {!form.editable &&
                                                        <p className={"btn btn--status btn--square btn--full--" + FormStatuses[form.status].class}>{FormStatuses[form.status].name}</p>
                                                    }
                                                    {form.editable &&
                                                        <button className="btn btn--status btn--square btn--full--resend" onClick={event => this.resendForm(event, member, form)}>Resend</button>
                                                    }
                                                    </div>
                                                )
                                            })}
                                        </div>

                                        <div className="status"><p className="status">STATUS | <span>{ hasOutstandingForm ? 'Awaiting Form' + (member.forms.length > 1?'s':'') : 'Complete' }</span></p></div>
                                    </div>
                                </div>
                            )
                        })}
                        {(this.context.team.length < 1 && !this.context.teamSettings.isTeamMember) &&
                                <div className="team-member-details">
                                    <div className="personal-details">
                                        <hgroup>
                                            <h4 className='personal-details__name'>There are no team members</h4>
                                        </hgroup>
                                    </div>
                                </div>
                        }

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

ViewTeamMembers.contextType = FormsContext;

export default ViewTeamMembers