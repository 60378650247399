import React from 'react';
import {
  Link
} from "react-router-dom";
import { FormsContext } from '../contexts'
import { FormStatuses } from '../constants'

class Home extends React.PureComponent {
    render() {
        return (
            <div className="forms">
                <div className="inner">
                    <h1 className="forms-title tablet">Your forms</h1>
                    <div className="forms-list">
                    {this.context.forms.map(form => {
                        const status = FormStatuses[form.status]
                        return (
                            <div className="forms-list-single" key={form.id}>
                                    <h2 className="forms-list-single__title">{form.name}{form.compulsory && <span>*</span>}</h2>
                                    <p className="forms-list-single__text">{form.description}</p>
                                    <div className="forms-list-single__info">
                                        <p className={"forms-list-single__info__status btn btn--status btn--square btn--full--" + status.class}>{status.name}</p>
                                    {form.editable &&
                                        <Link to={"/forms/" + form.id} className="forms-list-single__info__link">[ EDIT ]</Link>
                                    }
                                    </div>
                            </div>
                        )
                    })}
                    </div>
                </div>
            {this.context.teamSettings.hasTeam &&
                <div className="inner tablet">
                    <h1 className="forms-title tablet">Your team</h1>
                    <div className="forms-list">
                        <div className="forms-list-single">
                                <h2 className="forms-list-single__title">Add team member</h2>
                                <p className="forms-list-single__text">Add individual team members or upload a csv of your entire team.</p>
                                <div className="forms-list-single__info forms-list-single__info--end">
                                    <Link to="/team/add" className="forms-list-single__info__link">[ CLICK ]</Link>
                                </div>
                        </div>
                        <div className="forms-list-single">
                                <h2 className="forms-list-single__title">View team members</h2>
                                <p className="forms-list-single__text">Check if your team have filled in their forms.</p>
                                <div className="forms-list-single__info forms-list-single__info--end">
                                    <Link to="/team" className="forms-list-single__info__link">[ CLICK ]</Link>
                                </div>
                        </div>
                    </div>
                </div>
            }
            </div>
        )
    }
}

Home.contextType = FormsContext

export default Home