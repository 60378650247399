import React from 'react';
import {
  Redirect,
} from "react-router-dom";
import Loading from './Loading';
import { ValidateResetPasswordToken as ApiValidateResetPasswordToken, ResetPassword as ApiResetPassword } from '../utils/Api';
import { isAuthenticated } from '../utils/Auth';
import { FormErrors } from '../constants'

class ResetPassword extends React.PureComponent {

    state = {
        loading: true,
        submitting: false,
        valid: false,
        password: '',
        passwordConfirmation: '',
        errors: {},
    }

    constructor(props) {
        super(props)

        this.handleChange = this.handleChange.bind(this)
        this.validate = this.validate.bind(this)
    }

    componentDidMount() {
        ApiValidateResetPasswordToken(this.props.match.params.token).then(data => {
            this.setState({
                ...this.state,
                loading: false,
                valid: true
            })
        }).catch(error => {
            this.setState({
                ...this.state,
                loading: false
            })
        })
    }

    handleChange(event) {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        })
    }

    validate(event) {
        event.preventDefault();

        if (this.state.submitting) {
            return
        }

        let errors = {}
        if (this.state.password.trim() === '') {
            errors['password'] = FormErrors.required
        }

        if (this.state.passwordConfirmation.trim() === '') {
            errors['passwordConfirmation'] = FormErrors.required
        } else if (this.state.password.trim() !== this.state.passwordConfirmation.trim()) {
            errors['passwordConfirmation'] = "Password fields must match"
        }

        if (Object.keys(errors).length > 0) {
            this.setState({
                ...this.state,
                errors
            })
            return
        }

        this.setState({
            ...this.state,
            errors: {},
            submitting: true
        })

        ApiResetPassword(this.props.match.params.token, this.state.password).then(data => {
            alert('Password reset! Please login with your new password to continue.');
            this.props.history.push('/login')
        }).catch(error => {
            alert('There was an issue.');

            this.setState({
                ...this.state,
                errors: {},
                submitting: false
            })
        })

    }

    render() {
        if (isAuthenticated()) {
            return <Redirect
                to={{
                  pathname: "/"
                }} />
        }

        if (this.state.loading) {
            return <Loading />
        }

        if (!this.state.valid) {
            return <Redirect
                to={{
                  pathname: "/login"
                }} />
        }

        return (
            <div className="sign-in">
                <div className="inner center">
                    <div className="wrapper">
                        <hgroup className="heading-group">
                            <h2 className="heading-group__title" >Reset Password</h2>
                            <h4 className="heading-group__subtitle">Enter a new password below</h4>
                        </hgroup>
                        <form onSubmit={this.validate} method="post" className="sign-in__form form">
                            <div className="form__group form__group--text">
                                <h5 className='form__group__title'>Create Password</h5>
                                <input type="password" id="password" name="password" className="input input--height input--text" placeholder="" value={this.state.password} onChange={this.handleChange} />
                            {this.state.errors["password"] &&
                                <div className="form__error">{this.state.errors["password"]}</div>
                            }
                            </div>

                            <div className="form__group form__group--text">
                                <h5 className='form__group__title'>Confirm Password</h5>
                                <input type="password" id="passwordConfirmation" name="passwordConfirmation" className="input input--height input--text" placeholder="" value={this.state.passwordConfirmation} onChange={this.handleChange} />
                            {this.state.errors["passwordConfirmation"] &&
                                <div className="form__error">{this.state.errors["passwordConfirmation"]}</div>
                            }
                            </div>

                            <div className="form__group form__group--inline form__group--between">
                                <div className="form__group__half">
                                    <button id="submit" type="submit" className="btn btn--full--blue">{this.state.submitting?'Resetting...':'Reset'}</button>
                                </div>
                            </div>

                        </form>

                    </div>
                </div>
            </div>
        )
    }
}

export default ResetPassword
