import React from 'react';
import {
  Redirect,
} from "react-router-dom";
import Loading from './Loading';
import { checkExistingAuthToken, isAuthenticated, setAuthToken } from '../utils/Auth';
import { ValidateInviteToken as ApiValidateInviteToken, Register as ApiRegister } from '../utils/Api';
import { FormErrors } from '../constants'

class Register extends React.PureComponent {
    state = {
        email: '',
        data: {
            emailConfirmation: '',
            firstName: '',
            lastName: '',
            company: '',
            phone: '',
            password: '',
            passwordConfirmation: '',
            terms: false,
        },
        loading: true,
        valid: false,
        submitting: false,
        requiredFields: {
            name: true,
            company: true,
            phone: true
        },
        errors: {}
    }

    constructor(props) {
        super(props)

        this.handleChange = this.handleChange.bind(this)
        this.validate = this.validate.bind(this)
    }

    componentDidMount() {
        ApiValidateInviteToken(this.props.match.params.token).then(data => {
            this.setState({
                ...this.state,
                loading: false,
                valid: true,
                requiredFields: data.data.requiredFields,
                email: data.data.email

            })
        }).catch(error => {
            this.setState({
                ...this.state,
                loading: false
            })
        })
    }

    handleChange(event) {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [event.target.name]: (event.target.type === "checkbox"?event.target.checked:event.target.value)
            }
        })
    }

    validate(event) {
        event.preventDefault();

        if (this.state.submitting) {
            return
        }

        let errors = {}
        if (this.state.data.emailConfirmation.trim() === '') {
            errors['emailConfirmation'] = FormErrors.required
        }

        if (this.state.requiredFields.name) {
            if (this.state.data.firstName.trim() === '') {
                errors['firstName'] = FormErrors.required
            }

            if (this.state.data.lastName.trim() === '') {
                errors['lastName'] = FormErrors.required
            }
        }

        // if (this.state.requiredFields.company && this.state.data.company.trim() === '') {
        //     errors['company'] = FormErrors.required
        // }

        if (this.state.requiredFields.phone && this.state.data.phone.trim() === '') {
            errors['phone'] = FormErrors.required
        }

        if (this.state.data.password.trim() === '') {
            errors['password'] = FormErrors.required
        }

        if (this.state.data.passwordConfirmation.trim() === '') {
            errors['passwordConfirmation'] = FormErrors.required
        } else if (this.state.data.password.trim() !== this.state.data.passwordConfirmation.trim()) {
            errors['passwordConfirmation'] = "Password fields must match"
        }

        if (!this.state.data.terms) {
            errors['terms'] = "You must agree before continuing."
        }

        if (Object.keys(errors).length > 0) {
            this.setState({
                ...this.state,
                errors
            })
            return
        }

        this.setState({
            ...this.state,
            errors: {},
            submitting: true
        })

        ApiRegister(
            this.props.match.params.token,
            this.state.data.emailConfirmation,
            this.state.data.firstName,
            this.state.data.lastName,
            this.state.data.company,
            this.state.data.phone,
            this.state.data.password
        ).then(response => {

            setAuthToken(response.data.token)
            checkExistingAuthToken()

            this.props.getInitialData();

            this.props.history.push('/')

        }).catch(data => {
            this.setState({
                ...this.state,
                errors: data.data.errors,
                submitting: false
            })
        })

    }

    render() {
        if (isAuthenticated()) {
            return <Redirect
                to={{
                  pathname: "/"
                }} />
        }

        if (this.state.loading) {
            return <Loading />
        }

        if (!this.state.valid) {
            return <InvalidToken />
        }

        return (
            <div className="register">
                <div className="inner">
                    <div className="wrapper">
                        <hgroup className="heading-group">
                            <h2 className="heading-group__title heading-group__title--reduce">Welcome</h2>
                            <h4 className="heading-group__subtitle">Register to continue</h4>
                        </hgroup>

                        <form onSubmit={this.validate} method="post" className="register__form form">
                            <div className="form__group form__group--text">
                                <h5 className='form__group__title'>Email Address</h5>
                                <input type="text" id="email" name="email" className="input input--height input--text" value={this.state.email} disabled={true} />
                            </div>
                            <div className="form__group form__group--text">
                                <h5 className='form__group__title'>Confirm Email Address</h5>
                                <input type="text" id="emailConfirmation" name="emailConfirmation" className="input input--height input--text" placeholder="" value={this.state.emailConfirmation} onChange={this.handleChange} />
                            {this.state.errors["emailConfirmation"] &&
                                <div className="form__error">{this.state.errors["emailConfirmation"]}</div>
                            }
                            </div>
                        {this.state.requiredFields.name &&
                            <div className="form__group form__group--inline form__group--between form__group--text">
                                <div className="form__group__half">
                                    <h5 className='form__group__title'>First Name</h5>
                                    <input type="text" id="firstName" name="firstName" className="input input--height input--text input--text--half" placeholder="" value={this.state.firstName} onChange={this.handleChange} />
                                {this.state.errors["firstName"] &&
                                    <div className="form__error">{this.state.errors["firstName"]}</div>
                                }
                                </div>
                                <div className="form__group__half">
                                    <h5 className='form__group__title'>Last Name</h5>
                                    <input type="text" id="lastName" name="lastName" className="input input--height input--text input--text--half" placeholder="" value={this.state.lastName} onChange={this.handleChange} />
                                {this.state.errors["lastName"] &&
                                    <div className="form__error">{this.state.errors["lastName"]}</div>
                                }
                                </div>
                            </div>
                        }

                        {this.state.requiredFields.company &&
                            <div className="form__group form__group--text">
                                <h5 className='form__group__title'>Company/Organisation/Team (optional)</h5>
                                <input type="text" id="company" name="company" className="input input--height input--text" placeholder="" value={this.state.company} onChange={this.handleChange} />
                            {this.state.errors["company"] &&
                                <div className="form__error">{this.state.errors["company"]}</div>
                            }
                            </div>
                        }

                        {this.state.requiredFields.phone &&
                            <div className="form__group form__group--text">
                                <h5 className='form__group__title'>Phone</h5>
                                <input type="text" id="phone" name="phone" className="input input--height input--text" placeholder="" value={this.state.phone} onChange={this.handleChange} />
                            {this.state.errors["phone"] &&
                                <div className="form__error">{this.state.errors["phone"]}</div>
                            }
                            </div>
                        }

                            <div className="form__group form__group--text">
                                <h5 className='form__group__title'>Create Password</h5>
                                <input type="password" id="password" name="password" className="input input--height input--text" placeholder="" value={this.state.password} onChange={this.handleChange} />
                            {this.state.errors["password"] &&
                                <div className="form__error">{this.state.errors["password"]}</div>
                            }
                            </div>

                            <div className="form__group form__group--text">
                                <h5 className='form__group__title'>Confirm Password</h5>
                                <input type="password" id="passwordConfirmation" name="passwordConfirmation" className="input input--height input--text" placeholder="" value={this.state.passwordConfirmation} onChange={this.handleChange} />
                            {this.state.errors["passwordConfirmation"] &&
                                <div className="form__error">{this.state.errors["passwordConfirmation"]}</div>
                            }
                            </div>

                            <div className="form__group form__group--padded form__group--checkbox form__group--inline">
                                <input id="terms" type="checkbox" name="terms" className="input input--checkbox" checked={this.state.terms} onChange={this.handleChange} />
                                <label htmlFor="terms">I agree to BCF’s <a href="https://www.bigchurchfestival.com/privacy-policy/" target="_blank" rel="noreferrer noopener">Privacy Policy</a></label>
                            {this.state.errors["terms"] &&
                                <div className="form__error">{this.state.errors["terms"]}</div>
                            }
                            </div>

                            <button id="submit" type="submit" className="btn btn--full--blue">{this.state.submitting?"Registering...":"Register"}</button>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

function InvalidToken() {
    return (
        <div className="terms">
            <div className="inner inner--background small">
                <hgroup className="heading-group heading-group--small">
                    <h2 className="heading-group__title heading-group__title--small">Invite invalid</h2>
                </hgroup>
                <div className="text-block">
                    <p>
                        The invitation you're trying to use is no longer valid.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Register