import React from 'react';
// import {
//   Redirect
// } from "react-router-dom";
import { LoadSingleForm as ApiLoadSingleForm, SubmitSingleForm as ApiSubmitSingleForm, GetSingleFormUploadPolicy as ApiGetSingleFormUploadPolicy } from '../utils/Api';
import Loading from './Loading';
import Form from '../components/Form';
import { FormsContext } from '../contexts'

class SingleFormPage extends React.PureComponent {

    state = {
        form: {},
        loading: true
    }

    constructor(props) {
        super(props)

        this.submit = this.submit.bind(this)
        this.getUploadPolicy = this.getUploadPolicy.bind(this)
    }

    submit() {
        const token = this.props.match.params.token
        return ApiSubmitSingleForm(token, this.context.answers)
    }

    getUploadPolicy(questionId, fileName, contentType) {
        const token = this.props.match.params.token
        return ApiGetSingleFormUploadPolicy(token, questionId, fileName, contentType)
    }

    componentDidMount() {
        this.loadForm()
    }

    loadForm() {
        const token = this.props.match.params.token
        ApiLoadSingleForm(token)
        .then(data => {
            this.setState({
                form: data.data.form,
                loading: false
            })
        })
        .catch(error => {
            this.setState({
                form: false,
                loading: false
            })
        })
    }

    render() {
        if (this.state.loading) {
            return <Loading />
        }
        if (this.state.form === false) {
            return <NotFound />
        }

        return (
            <div className="form-page">
                <Form form={this.state.form} history={this.props.history} onSubmit={this.submit} getUploadPolicy={this.getUploadPolicy} single />
            </div>
        )
    }
}

SingleFormPage.contextType = FormsContext;

export default SingleFormPage

function NotFound() {
    return (
        <div className="terms">
            <div className="inner inner--background small">
                <hgroup className="heading-group heading-group--small">
                    <h2 className="heading-group__title heading-group__title--small">Form no longer available</h2>
                </hgroup>
                <div className="text-block">
                    <p>
                        The form you're trying to find has already been filled out or is no longer available.
                    </p>
                </div>
            </div>
        </div>
    )
}