import axios from 'axios'
import { removeAuthToken } from './Auth'

axios.defaults.baseURL = process.env.REACT_APP_API;

axios.interceptors.response.use(null, function(err) {
  if (err.response.status === 401 && window.location.pathname !== '/login') {
    removeAuthToken()
    window.location.reload()
    return
  }
  return Promise.reject(err);
});

export function GetAccount() {
  return axios.get('/get-account')
    .then(response => response.data)
}

export function LoadForms() {
  return axios.get('/get-forms')
    .then(response => response.data)
    .catch(error => {
      throw error.response.data
    })
}

export function LoadSingleForm(token) {
  return axios.post('/get-single-form', {
    token
  })
    .then(response => response.data)
}

export function LoadTeam() {
  return axios.get('/get-team')
    .then(response => response.data)
    .catch(error => {
      throw error.response.data
    })
}

export function Login(event, email, password) {
  return axios.post('/login', {
    event,
    email,
    password
  }).then(response => response.data)
}

export function SubmitForm(formId, answers) {
  return axios.post('/save-form', {
    form_id: formId,
    answers: {
      ...answers
    }
  })
  .then(response => response.data)
  .catch(error => {
    throw error.response.data
  })
}

export function SubmitSingleForm(token, answers) {
  return axios.post('/save-single-form', {
    token,
    answers: {
      ...answers
    }
  })
  .then(response => response.data)
  .catch(error => {
    throw error.response.data
  })
}

export function AddTeamMember(firstName, lastName, email, phone) {
  return axios.post('/add-team-member', {
    firstName,
    lastName,
    email,
    phone
  }).then(response => response.data)
  .catch(error => {
    throw error.response.data
  })
}

export function AddTeamMembers(members) {
  return axios.post('/add-team-members', {
    members
  })
  .then(response => response.data)
  .catch(error => {
    throw error.response.data
  })
}

export function RemoveTeamMember(id) {
  return axios.post('/remove-team-member', {
    id
  }).then(response => response.data)
  .catch(error => {
    throw error.response.data
  })
}

export function GetEticket(id) {
  return axios.post('/get-eticket', {
    id
  }).then(response => response.data)
  .catch(error => {
    throw error.response.data
  })
}

export function ToggleTeamMember(isTeamMember) {
  return axios.post('/toggle-team-member', {
    isTeamMember
  }).then(response => response.data)
}

export function ResendTeamMemberForm(formId, memberId) {
  return axios.post('/resend-team-member-form', {
    formId,
    memberId
  }).then(response => response.data)
}

export function LoadEvents() {
  return axios.get('/load-events')
    .then(response => response.data)
}

export function ValidateInviteToken(token) {
  return axios.post('/validate-invite-token', {
    token
  })
    .then(response => response.data)
}

export function Register(token, emailConfirmation, firstName, lastName, company, phone, password) {
  return axios.post('/register', {
    token,
    emailConfirmation,
    firstName,
    lastName,
    company,
    phone,
    password
  }).then(response => response.data)
  .catch(error => {
    throw error.response.data
  })
}

export function ValidateResetPasswordToken(token) {
  return axios.post('/validate-reset-password-token', {
    token
  })
    .then(response => response.data)
}

export function ResetPassword(token, password) {
  return axios.post('/reset-password', {
    token,
    password
  })
  .then(response => response.data)
}

export function SendForgotPassword(event, email) {
  return axios.post('/forgot-password', {
    event,
    email
  })
  .then(response => response.data)
}

export function GetUploadPolicy(formId, questionId, fileName, contentType) {
  return axios.post('/get-upload-policy', {
    formId,
    questionId,
    fileName,
    contentType
  })
  .then(response => {
    return response.data.data
  })
}

export function GetSingleFormUploadPolicy(token, questionId, fileName, contentType) {
  return axios.post('/get-single-form-upload-policy', {
    token,
    questionId,
    fileName,
    contentType
  })
  .then(response => {
    return response.data.data
  })
}
