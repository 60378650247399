export const FormStatuses = {
    1: {
        name: 'Incomplete',
        class: 'incomplete',
        canEdit: true
    },
    2: {
        name: 'Action Needed',
        class: 'action-needed',
        canEdit: true
    },
    3: {
        name: 'Awaiting Approval',
        class: 'awaiting-approval',
        canEdit: false
    },
    4: {
        name: 'Complete',
        class: 'complete',
        canEdit: false
    }
}

export const FormErrors = {
    required: 'This field is required.'
}