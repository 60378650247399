import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";

import * as Api from './utils/Api';
import { isAuthenticated, PrivateRoute, checkExistingAuthToken, removeAuthToken } from './utils/Auth';
import { FormsContext } from './contexts'

import Header from './components/Header';
import Footer from './components/Footer';

import Loading from './pages/Loading';
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Register from './pages/Register';
import NotFound from './pages/NotFound';
import AddTeamMember from './pages/AddTeamMember';
import ViewTeamMembers from './pages/ViewTeamMembers';
import Form from './pages/Form';
import SingleForm from './pages/SingleForm';

import './styles/main.scss'

class App extends Component {

  constructor(props) {
    super(props)

    checkExistingAuthToken();

    this.getInitialData = this.getInitialData.bind(this)
    this.updateForm = this.updateForm.bind(this)
    this.updateAnswer = this.updateAnswer.bind(this)
    this.setAnswers = this.setAnswers.bind(this)
    this.clearAnswers = this.clearAnswers.bind(this)
    this.reloadTeam = this.reloadTeam.bind(this)
    this.removeTeamMember = this.removeTeamMember.bind(this)
    this.setLoading = this.setLoading.bind(this)
    this.toggleIsTeamMember = this.toggleIsTeamMember.bind(this)
    this.setAccount = this.setAccount.bind(this)
    this.doLogout = this.doLogout.bind(this)
    this.canAddTeamMember = this.canAddTeamMember.bind(this)
    this.countTeamMembers = this.countTeamMembers.bind(this)
    this.remainingTeamMemberLimit = this.remainingTeamMemberLimit.bind(this)
    this.loadEvents = this.loadEvents.bind(this)
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this)

    this.state = {
      loading: isAuthenticated(),
      setLoading: this.setLoading,
      events: [],
      eventsLoaded: false,
      loadEvents: this.loadEvents,
      answers: {},
      forms: [],
      updateForm: this.updateForm,
      updateAnswer: this.updateAnswer,
      setAnswers: this.setAnswers,
      clearAnswers: this.clearAnswers,
      team: [],
      teamSettings: {},
      account: {},
      setAccount: this.setAccount,
      reloadTeam: this.reloadTeam,
      removeTeamMember: this.removeTeamMember,
      toggleIsTeamMember: this.toggleIsTeamMember,
      canAddTeamMember: this.canAddTeamMember,
      countTeamMembers: this.countTeamMembers,
      remainingTeamMemberLimit: this.remainingTeamMemberLimit,
      mobileMenuVisible: false
    }

    if (isAuthenticated()) {
      this.getInitialData(false)
    }
  }

  getInitialData(loaded = true) {
    if (loaded) {
      this.setState({
        ...this.state,
        loading: true
      })
    }

    Promise.all([Api.LoadForms(), Api.LoadTeam(), Api.GetAccount()]).then(values => {
      this.setState({
        ...this.state,
        forms: values[0].data,
        team: values[1].data.team,
        teamSettings: values[1].data.settings,
        account: values[2].data,
        loading: false
      })
    })
  }

  toggleMobileMenu() {
      this.setState({
        ...this.state,
        mobileMenuVisible: !this.state.mobileMenuVisible
      })
  }

  loadEvents() {
    if (this.state.eventsLoaded > 0) {
      return
    }

    this.setLoading();
    Api.LoadEvents().then(data => {
      this.setState({
        ...this.state,
        events: data.data,
        eventsLoaded: true,
        loading: false
      });
    })
  }

  setLoading(loading) {
    this.setState({
      ...this.state,
      loading
    })
  }

  setAccount(account) {
    this.setState({
      ...this.state,
      account
    })
  }

  updateAnswer(id, value) {
    this.setState({
      ...this.state,
      answers: {
        ...this.state.answers,
        [id]: value
      }
    })
  }

  updateForm(id, form) {
    this.setState(state => {
      const forms = [...state.forms]
      forms.forEach((f, i) => {
        if (parseInt(f.id) === parseInt(id)) {
          forms[i] = form
        }
      })

      return {
        forms
      }
    })
  }

  setAnswers(answers) {
    this.setState({
      ...this.state,
      answers
    })
  }

  clearAnswers() {
    this.setState({
      ...this.state,
      answers: {}
    })
  }

  reloadTeam() {
    this.setState({
      ...this.state,
      loading: true
    })

    Api.LoadTeam().then(response => {
      this.setState({
        ...this.state,
        team: response.data.team,
        teamSettings: response.data.settings,
        loading: false
      })
    })
  }

  removeTeamMember(id) {
    let team = this.state.team
    team.forEach((member, i) => {
      if (member.id === id) {
        team.splice(i, 1);
        return false
      }
    })
    this.setState({
      ...this.state,
      team
    })
  }

  toggleIsTeamMember(isTeamMember) {
    this.setState({
      ...this.state,
      teamSettings: {
        ...this.state.teamSettings,
        isTeamMember: isTeamMember
      }
    })
  }

  doLogout() {
    removeAuthToken();
    this.setState({
      ...this.state,
      account: {}
    })
    // this.props.history.push('/');
  }

  canAddTeamMember(additional = 1) {
    const remaining = this.remainingTeamMemberLimit()
    if (remaining === null) {
      return true
    }

    return !(additional > remaining)
  }

  remainingTeamMemberLimit() {
    if (this.state.teamSettings.limit === null) {
      return null
    }
    return this.state.teamSettings.limit - this.countTeamMembers()
  }

  countTeamMembers() {
    return this.state.team.length + (this.state.teamSettings.isTeamMember?1:0)
  }

  render() {
    return (
      <FormsContext.Provider value={this.state}>
        <main className={"main" + (this.state.mobileMenuVisible?" nav-open":"")}>
          <Router>
            <div>
              <Header doLogout={this.doLogout}
                toggleMobileMenu={this.toggleMobileMenu}
                name={this.state.account.name}
                company={this.state.account.company}
                hasTeam={!this.state.loading && this.state.teamSettings.hasTeam} />
            {this.state.loading &&
              <Loading />
            }
            {!this.state.loading &&
              <Switch>
                <Route path="/single-form/:token" exact component={SingleForm} />
                <Route path="/invite/:token" exact render={props => <Register {...props} getInitialData={() => this.getInitialData()} />} />
                <Route path="/login" exact render={props => <SignIn {...props} getInitialData={() => this.getInitialData()} />} />
                <Route path="/reset-password/:token" exact component={ResetPassword} />
                <Route path="/forgot-password" exact component={ForgotPassword} />
                <PrivateRoute path="/team/add" exact component={AddTeamMember} />
                <PrivateRoute path="/team" exact component={ViewTeamMembers} />
                <PrivateRoute path="/forms/:id" exact component={Form} />
                <PrivateRoute path="/" exact  component={Home} />
                <Route component={NotFound} />
              </Switch>
            }
            </div>
          </Router>
          <Footer />
        </main>
      </FormsContext.Provider>
    );
  }
}

export default App;
